/* The following styles get applied both on the front of your site and in the editor. */
.wp-block-gca-block-scroller {
  /* The below is just to make the code more readable */
  --max-screen: 100vw;
  --half-screen: 50vw;
  --min: calc(theme(screens.2xl) - calc(var(--wp--style--root--padding-left) * 2));
  --max: calc(var(--max-screen) - calc(var(--wp--style--root--padding-left) * 2));
  --gallery-side-padding: calc(var(--half-screen) - min(var(--min), var(--max)) / 2);

  position: relative;
  margin-top: 0;

  /* Pull out of container */

  .scroll-container {
    position: relative;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    overflow: scroll;
    width: 100vw;
    scroll-padding: var(--gallery-side-padding);
    @apply -mb-md pb-md;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: rgba(0 0 0 / 0%);
    }
  }

  .scroll-container > div {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: max-content;
    grid-auto-flow: column;
    width: fit-content;
    padding: 0 var(--gallery-side-padding);

    > .wp-block-post-template {
      width: fit-content;
      grid-template-rows: 1fr;
      container-type: normal;
      /* grid-template-columns: repeat(auto-fill, minmax(min(320px, 100%), 1fr));
      grid-template-columns: max-content; */
      grid-auto-flow: column;

      > :is(*, div, li) {
        scroll-snap-align: start;
        position: relative;
        box-sizing: border-box;
        width: clamp(18.75rem, 16.346rem + 9.615vw, 22.5rem);
      }
    }
  }

  .scroll-controls {
    --control-arrow-color: theme(colors.maroon.DEFAULT);
    --control-arrow-color-hover: theme(colors.white);
    --control-background: theme(colors.grey.lightest);
    --control-background-hover: theme(colors.maroon.DEFAULT);
    --control-background-active: theme(colors.grey.lightest);
    --control-arrow-height: 44px;
    padding: 0 var(--gallery-side-padding);
    @apply flex items-center justify-end;

    .controls {
      display: grid;
      z-index: 50;
      grid-auto-flow: column;
      @apply gap-2xs;
    }

    button {
      width: var(--control-arrow-height);
      height: var(--control-arrow-height);
      color: var(--control-arrow-color);
      @apply grid items-center justify-center rounded-full border-2 transition-colors;

      &:hover {
        background: var(--control-background-hover);
        color: var(--control-arrow-color-hover);
      }
    }
  }
}

.wp-block-group.alignfull:has(.wp-block-gca-block-scroller) .wp-block-gca-block-scroller {
  --side-margin: calc(calc(50vw - min(theme(screens.2xl), 100vw) / 2) * -1 - var(--wp--style--root--padding-left));
  padding-left: 0;
  padding-right: 0;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
}

.wp-block-gca-block-scroller.horizontal-scroll-trigger {
  .scroll-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-inline: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
    gap: var(--space-10-20);
    min-width: max-content;
  }

  .scroll-container > div,
  .scroll-container > figure {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
